html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

ul {
  padding: 0 20px;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:300');
